import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const AboutPage = props => {
  return (
    <Layout>
      <SEO title='Developer Education as a Service' pathname='work-with-me' />
      <div className='about-me'>
        <div className='post container'>
          <h1>Work With Me</h1>
          <p className='intro-content' style={{ margin: '0 auto' }}>
            I love teaching people how to code, and I'd love to partner with you to provide educational materials on your platform or for your product.
          </p>
          <section>
            <h2>Workshops</h2>
            <p>
              Teaching workshops is my forte! I teach live, online classes as my all-day everyday job at General Assembly. I've also delivered workshops at numerous conferences and meetups.
            </p>
            <p>
              My favorite format is a Zoom workshop that attendees register for which is posted on YouTube after, but I'm open to ideas.
            </p>
            <p>
              <a href='https://www.youtube.com/watch?v=yoaZnAB9_yk'>Here's</a> an example of a workshop following that format that I did in collaboration with Hasura.
            </p>
            <p>Here are a few other examples of my talks:</p>
            <ul>
              <li><a href='https://www.youtube.com/watch?v=9Q_BRaqQt8Y'>Yes, You Should Write That Blog Post</a></li>
              <li><a href='https://www.youtube.com/watch?v=rm-dFWP9bB4'>Web Ready Augmented Reality in 47 Lines of Code</a></li>
              <li><a href='https://www.youtube.com/watch?v=rrFdf0xSksk'>Those Who Can Do Should Also Teach</a></li>
            </ul>
            <p>I also create short videos on technical topics on <a href='https://egghead.io/instructors/ali-spittel'>Egghead.io</a>.</p>
            <p>
              <b>My rate for a one-time workshop is $4,000.</b> This includes me tweeting to my 74k+ audience about the workshop twice before the event and once with the recording after. We can also discuss other marketing for the workshop.
            </p>
            <p>
              These workshops take at least two months to prepare for.
            </p>
          </section>
          <section>
            <h2>Blog Posts</h2>
            <p>
              My blog posts have had well over 1.5 million reads over the past two and a half years. They've reached the front pages of Hacker News and Reddit, and have been featured in dozens of publications. You can read my posts on this site, since you're already here, or on <a href='https://dev.to/aspittel'>dev.to</a>.
            </p>
            <p>
              I've also collaborated with several companies to write blog posts for them, either hosted on my site or theirs.
            </p>
            <p>Here are a couple examples: </p>
            <ul>
              <li>
                <a href='https://welearncode.com/hasura-101/'>Hasura 101: Building a Realtime Game with Graphql, Postgres, and React</a>
              </li>
              <li>
                <a href='https://www.sitepoint.com/what-is-functional-programming/'>What is Functional Programming?</a>
              </li>
              <li>
                <a href='https://dmv.myhatchpad.com/insight/a-software-engineers-guide-to-building-a-technical-portfolio/'>A software engineer’s guide to building a technical portfolio</a>
              </li>
              <li>
                <a href='https://cult.honeypot.io/reads/teaching-made-me-better-developer'>How Teaching Made Me a Better Developer</a>
              </li>
            </ul>
            <p>
              I am willing to host the post on my own site, on your site, and/or to my 39k+ dev.to audience. I will also send out two tweets promoting the post when it is published.
            </p>
            <p>
              My rates for blog posts are:
              <ul>
                <li>$200 for an interview that gets repurposed to a blog post on your site.</li>
                <li>$400 for a non-technical piece under 1,000 words.</li>
                <li>$600 for a technical introduction to a topic with short code snippets (~1,500 words).</li>
                <li>$1,000 for a full tutorial with a code demo (~2,000+ words).</li>
              </ul>
            </p>
          </section>
          <section>
            <h2>Ongoing Partnerships</h2>
            <p>Doing a workshop + a blog post on the same topic where I can re-use a demo is significantly faster for me than starting from scratch for both. I offer a package for a workshop + blog post that costs $4,500.</p>
            <p>I'd also love to hear other ways to work with your company and to discuss doing a series of workshops or blog posts at a discounted price. Please schedule a time with me to chat via <a href="mailto:hello@welearncode.com">my email</a> to explore these types of partnerships!</p>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
